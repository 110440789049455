<style>
html {
  overflow: auto;
  overscroll-behavior: auto;
}
</style>
<template>
  <div class="page-results override-container">
    <button class="scroll-down" :class="{'scroll-down-mobile': this.deviceType === deviceTypes.MOBILE || this.deviceType === deviceTypes.TABLET}" v-on:click="scrollToBottom">
      <svg v-if="scrollDownArrow" :key="'up-down'" width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
        <path
            d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/>
      </svg>
      <svg v-else :key="'up-arrow'" width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
        <path
            d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"/>
      </svg>
    </button>
    <h2 class="h-2 text-center">{{ $t('page-results.title') }}</h2>
    <p v-if="hasAcceptableRightVision || hasAcceptableLeftVision || hasPoorRightVision || hasPoorLeftVision"
       class="body bold text-optometrist">
      {{
        $t('page-results.acceptable-or-impaired-vision')
      }}
    </p>
    <p>{{ $t('page-results.sub-title') }}</p>
    <div class="content">
      <table style="text-align: center">
        <colgroup>
          <col class="col-left-eye">
          <col class="col-vision">
          <col class="col-right-eye">
        </colgroup>
        <thead>
        <tr>
          <th class="left-eye-here">{{ $t('page-results.left-eye') }}</th>
          <th></th>
          <th class="right-eye-here">{{ $t('page-results.right-eye') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <div class="vision-result" v-if="hasPoorLeftVision">
              <p v-html="poorVisionLeftDisplay"></p>
              <img :src="rightArrow" class="arrow" alt="right arrow"/>
            </div>
          </td>
          <td class="poor-vision">{{ $t('page-results.poor-vision-text') }}</td>
          <td>
            <div class="vision-result" v-if="hasPoorRightVision">
              <img :src="leftArrow" class="arrow" alt="left arrow"/>
              <p v-html="poorVisionRightDisplay"></p>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="vision-result" v-if="hasAcceptableLeftVision">
              <p v-html="acceptableVisionLeftDisplay"></p>
              <img :src="rightArrow" class="arrow" alt="right arrow"/>
            </div>
          </td>
          <td class="acceptable-vision">{{ $t('page-results.acceptable-vision-text') }}</td>
          <td>
            <div class="vision-result" v-if="hasAcceptableRightVision">
              <img :src="leftArrow" class="arrow" alt="left arrow"/>
              <p v-html="acceptableVisionRightDisplay"></p>
            </div>
          </td>

        </tr>
        <tr>
          <td>
            <div class="vision-result" v-if="hasNormalLeftVision">
              <p v-html="normalVisionLeftDisplay"></p>
              <img :src="rightArrow" class="arrow" alt="right arrow"/>
            </div>
          </td>
          <td class="normal-vision">{{ $t('page-results.normal-vision-text') }}</td>
          <td>
            <div class="vision-result" v-if="hasNormalRightVision">
              <img :src="leftArrow" class="arrow" alt="left arrow"/>
              <p v-html="normalVisionRightDisplay"></p>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="texts">
        <div class="text white-bg">
          <p class="body override-result text-center">
            {{
              $t('page-results.results-based-input-distance', {
                distance: getDistance()
              })
            }}
          </p>
        </div>
        <div class="text white-bg text-center" v-if="!isAdvancedMode">
          <p class="body" v-html="$t('page-results.thank-you-taking-test')"></p>
        </div>
        <div class="text white-bg" v-if="isAdvancedMode">
          <p class="body" v-html="$t('page-results.for-everyone-advanced')"></p>
        </div>
      </div>

      <div class="actions margin-top-l margin-bottom-m">
        <button class="btn primary-bg small-btn body-small text-center"
                v-on:click="goToAboutPage">
          {{ $t('global.about-result') }}
        </button>

        <button class="btn primary-bg small-btn body-small text-center"
                v-on:click="restartTest">
          {{ $t('page-results.try-again') }}
        </button>
      </div>
    </div>

    <p class="body margin-top-l">{{ $t('global.go-to') }}:</p>
    <div class="links">
      <ul>
        <li>
          <a href="http://www.danmarktestersynet.dk" target="_blank">
            <img :src="danmarktestersynetLogo" alt="Danmark tester synet logo"/>
          </a>
        </li>
        <li>
          <a href="https://www.ojenforeningen.dk" target="_blank">
            <img :src="ojenforeningenLogoDa" alt="Ojenforeningen logo" v-if="getLanguage() === 'da'"/>
            <img :src="ojenforeningenLogo" alt="Ojenforeningen logo" v-else/>
          </a>
        </li>
        <li>
          <a href="https://www.eye-sight.org" target="_blank">
            <img :src="eyeSightLogo" alt="Eye sight logo"/>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {deviceTypes, maxErrorsPerTest, normalVisionPercent, testModes} from '@/constants';
import {getDeviceType, getIndexBasedOnDistance} from '@/utils/utils';
import {isTruthy} from '@/utils/boolean';
import {addResultEntry, createEntry} from '@/../airtable';
import store from '@/store';
import rightArrow from '@/assets/arrow_right.svg';
import leftArrow from '@/assets/arrow_left.svg';
import danmarktestersynetLogo from '@/assets/Danmark tester synet.svg';
import ojenforeningenLogoDa from '@/assets/Ojenforeningen.svg';
import ojenforeningenLogo from '@/assets/Logo.png';
import eyeSightLogo from '@/assets/Eyesight logo.svg';
import i18n from "@/i18n";

const add = (accumulator, value) => accumulator + value;

const getLogmarInformation = (logmarValue, distance) => {
  /**
   * Gets the info for a particular logmar from the logmarConversion array
   */
  const conversions = store.getters.getLogmarConversion[distance].conversion;
  const ret = conversions.filter(({value}) => value === logmarValue);

  if (ret.length > 0) {
    return ret[0];
  } else {
    return conversions[0];
  }
};

const formatEyeResult = (logmarValue, errorMessage, distance) => {
  /**
   * Format the result of a single eye to display on the screen
   */
  const logmarInfo = getLogmarInformation(logmarValue, distance);

  if (!logmarInfo) {
    const conversions = store.getters.getLogmarConversion[distance].conversion;
    return errorMessage + " " + conversions[0].meter;
  }

  const meter = logmarInfo.meter;
  // const foot = logmarInfo.foot;

  // return `${meter} (${foot})`;
  return `${meter}`;
};

export default {
  components: {},
  methods: {
    restartTest() {
      createEntry(this.$store, false);
      this.$store.commit('changePage', 'PageDistance')
    },
    handleScroll() {
      if(!this.blockListenerScroll) {
        const middlePosition = window.innerHeight / 2;
        const scrollPosition = window.scrollY;
        this.scrollDownArrow = scrollPosition < middlePosition;
      }
    },
    scrollToBottom() {
      this.blockListenerScroll = true;
      window.scrollTo({
        top: !this.scrollDownArrow ? 0 : document.body.scrollHeight - window.innerHeight,
        behavior: 'smooth'
      });
      this.scrollDownArrow = !this.scrollDownArrow;
      setTimeout(() => this.blockListenerScroll = false, 1000);
    },
    getLanguage() {
      return this.$i18n.locale;
    },
    getDistance() {
      return getIndexBasedOnDistance(this.$store.state.distance) === '40cm' ? i18n.global.t('global.distance-40-cm') : i18n.global.t('global.distance-2-m');
    },
    goBackHome() {
      window.location = this.$t('page-results.go-back-link');
    },
    goToAboutPage() {
      this.$router.push('/about');
      document.body.scroll({top: 0, behavior: 'smooth'})
    },
    visionText(logmarResult) {
      if (!logmarResult || logmarResult >= 0.4) {
        return this.$t('page-results.poor-vision');
      } else if (logmarResult > 0.2 && logmarResult <= 0.3) {
        return this.$t('page-results.acceptable-vision');
      } else {
        return this.$t('page-results.normal-vision');
      }
    },
    hasPoorVision(logmarResult) {
      return logmarResult == undefined || logmarResult >= 0.4;
    },
    hasAcceptableVision(logmarResult) {
      return logmarResult > 0.2 && logmarResult <= 0.3;
    },
    hasNormalVision(logmarResult) {
      return logmarResult <= 0.2;
    },
  },
  data() {
    return {
      normalVisionPercent,
      leftArrow,
      scrollDownArrow: true,
      deviceType: getDeviceType(),
      blockListenerScroll: false,
      rightArrow,
      danmarktestersynetLogo,
      ojenforeningenLogoDa,
      ojenforeningenLogo,
      eyeSightLogo,
      results: ((state) => {
        const distance = getIndexBasedOnDistance(state.distance);

        const logmars = this.$store.getters.getTestConfiguration[distance].logmar;
        const steps = 2 * logmars.map(({rings}) => rings).reduce(add, 0);

        const arrEyeResults = Object.keys(state.testData.rounds).map((eye) => {
          const eyeRounds = state.testData.rounds[eye];
          let logmar = undefined;
          let score = 0;

          eyeRounds.map((round) => {
            const roundScore = round.results
                .filter(isTruthy)
                .map((v) => +v)
                .reduce(add, 0);

            score += roundScore;

            // logmar calculation
            const roundFailed = round.results.length - roundScore;
            if (roundFailed < maxErrorsPerTest && round.results.length > 0) {
              logmar = round.logmar;
            }

          });
          return [eye, {score, logmar}];
        });

        const eyeResults = Object.fromEntries(arrEyeResults);

        // number of "rights"
        const successful = Object.keys(eyeResults)
            .map((eye) => eyeResults[eye].score)
            .reduce(add, 0);

        // logmar results
        const errorMessage = this.$t('page-results.error');
        const leftResultLogmar = eyeResults.left.logmar;
        const leftResult = formatEyeResult(eyeResults.left.logmar, errorMessage, distance);
        const rightResultLogmar = eyeResults.right.logmar;
        const rightResult = formatEyeResult(eyeResults.right.logmar, errorMessage, distance);

        const leftSnellen = getLogmarInformation(eyeResults.left.logmar, distance) || null;
        const rightSnellen = getLogmarInformation(eyeResults.right.logmar, distance) || null;

        return {
          steps,
          successful,
          leftResultLogmar,
          rightResultLogmar,
          leftResult,
          rightResult,
          leftSnellen,
          rightSnellen,
        };
      })(this.$store.state),
    };
  },
  computed: {
    deviceTypes() {
      return deviceTypes
    },
    hasPoorRightVision() {
      return this.hasPoorVision(this.results.rightResultLogmar);
    },
    hasPoorLeftVision() {
      return this.hasPoorVision(this.results.leftResultLogmar);
    },
    hasAcceptableRightVision() {
      return this.hasAcceptableVision(this.results.rightResultLogmar);
    },

    hasAcceptableLeftVision() {
      return this.hasAcceptableVision(this.results.leftResultLogmar);
    },
    hasNormalRightVision() {
      return this.hasNormalVision(this.results.rightResultLogmar);
    },
    hasNormalLeftVision() {
      return this.hasNormalVision(this.results.leftResultLogmar);
    },
    ratioSuccessful() {
      return this.results.successful / this.results.steps;
    },
    poorVisionRightDisplay() {
      return this.hasPoorRightVision ? (
          this.isAdvancedMode ?
              this.$t('page-results.poor-advanced-mode-vision-result-text', {snellenResult: this.results.rightSnellen.meter}) :
              this.$t('page-results.poor-basic-mode-vision-result-text')
      ) : '';
    },
    poorVisionLeftDisplay() {
      return this.hasPoorLeftVision ? (
          this.isAdvancedMode ?
              this.$t('page-results.poor-advanced-mode-vision-result-text', {snellenResult: this.results.leftSnellen.meter}) :
              this.$t('page-results.poor-basic-mode-vision-result-text')
      ) : '';
    },
    acceptableVisionRightDisplay() {
      return this.hasAcceptableRightVision ? (
          this.isAdvancedMode ?
              this.$t('page-results.acceptable-advanced-mode-vision-result-text', {snellenResult: this.results.rightSnellen.meter}) :
              this.$t('page-results.acceptable-basic-mode-vision-result-text')
      ) : '';
    },
    acceptableVisionLeftDisplay() {
      return this.hasAcceptableLeftVision ? (
          this.isAdvancedMode ?
              this.$t('page-results.acceptable-advanced-mode-vision-result-text', {snellenResult: this.results.leftSnellen.meter}) :
              this.$t('page-results.acceptable-basic-mode-vision-result-text')
      ) : '';
    },
    normalVisionRightDisplay() {
      return this.hasNormalRightVision ? (
          this.isAdvancedMode ?
              this.$t('page-results.normal-advanced-mode-vision-result-text', {snellenResult: this.results.rightSnellen.meter}) :
              this.$t('page-results.normal-basic-mode-vision-result-text')
      ) : ''
    },
    normalVisionLeftDisplay() {
      return this.hasNormalLeftVision ? (
          this.isAdvancedMode ?
              this.$t('page-results.normal-advanced-mode-vision-result-text', {snellenResult: this.results.leftSnellen.meter}) :
              this.$t('page-results.normal-basic-mode-vision-result-text')
      ) : ''
    },
    roundsPerEye() {
      const rounds = this.$store.state.roundsPerEye;
      // debug
      if (rounds !== 5 || rounds !== 10) {
        return 10;
      }
      return rounds;
    },
    distance() {
      return this.$store.state.distance;
    },
    distanceKey() {
      return getIndexBasedOnDistance(this.distance);
    },
    isAdvancedMode() {
      return this.$store.state.testMode === testModes.ADVANCED;
    },
    // first or second eye
    leftVisionText() {
      return this.visionText(this.results.leftResultLogmar)
    },
    rightVisionText() {
      return this.visionText(this.results.rightResultLogmar)
    },
    message() {
      const distance = this.distance === 0 ? '40-cm' : '2-m';
      const rounds = this.roundsPerEye;
      return `page-results.message-${rounds}-${distance}`;
    },
    disclaimer() {
      const rounds = this.roundsPerEye;
      return `page-results.disclaimer-${rounds}`;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    addResultEntry(this.$store, this.results);
  },
};
</script>
